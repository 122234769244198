<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-03-13 17:22:04
-->
<template>
    <div class="blueBg">
        <div class="back" @click="$router.push('/')">
            <van-icon name="arrow-left" color="#fff" size="0.45rem" />
            返回首页
        </div>
        <img src="/static/img/intro/intro-bg.png" class="w-100">
        <div class="intro-title mar-l" style="padding:1rem 0rem 0.5rem 0.35rem">
            <h3>用户如何进入</h3>
            <h2>电脑端专家记？</h2>
        </div>
        <div class="intro-pad">
            <div class="intro-white">
                <div class="center">
                    <img src="/static/img/intro/xia.png" class="w12" style="position: relative; top:-0.5rem ">
                </div>
                <div class="question-title">
                    <p class="numBg">1</p>
                    <p>使用微信电脑端点击<span class="fc">通讯录</span>，再选择<span class="fc">公众号，</span>找到<span class="fc">专家记公众号</span>。</p>
                </div>
                <img src="/static/img/intro/pc/1.png" class="w-100">
                <div class="question-title">
                    <p class="numBg">2</p>
                    <p>进入公众号后，点击底部菜单的<span class="fc">专家记</span>。</p>
                </div>
                <img src="/static/img/intro/pc/2.png" class="w-100">

                <div class="question-title">
                    <p class="numBg">3</p>
                    <p>按照注册列举的注册需求信息，输填写真实的用户信息。</p>
                </div>
                <img src="/static/img/intro/pc/4.png" class="w-100">

                <div class="question-title">
                    <p class="numBg">4</p>
                    <p>信息填写完成后，勾选<span class="fc">同意平台使用规则并</span>点击底部的<span class="fc">确定注册</span>按钮，即可使用专家记。</p>
                </div>
                <img src="/static/img/intro/pc/5.png" class="w-100">

                <div class="center">
                    <img src="/static/img/intro/shang.png" class="w12" style="position: relative; bottom:-0.7rem">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from 'vant'
export default {
    data(){
        return{
        }
    },

    components:{
      [Icon.name]:Icon
    }
}
</script>

<style scoped>
  .back{
    position: absolute;
    top: 0.3rem;
    left:0.5rem;
    padding: 0.1rem 0.3rem 0.1rem 0.2rem;
    border-radius: 0.6rem;
    background: #009A86;
    display: flex;
    align-items: center;
    color: #fff;
  }
</style>
